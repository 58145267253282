<template>
  <el-dialog
    class="menu-update-dialog"
    :title="lang_data == 1 ? '添加项目' : 'Add the project'"
    :visible="true"
    :before-close="
      (() => {
        this.closeModal();
      }).bind(this)
    "
    width="700px"
    top="19vh"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form ref="form" label-position="left" label-width="100px" :model="form">
      <el-form-item
        :label="lang_data == 1 ? '选择项目:' : 'Select the project：'"
      >
        <el-select
          v-model="form.project_id"
          @change="onSelectProject"
          size="mini"
        >
          <el-option
            :label="item.project_name"
            :value="item.project_id"
            v-for="item in projectlist"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <template v-if="form.project">
        <template
          v-if="form.project.input_type == 1 || form.project.input_type == 3"
        >
          <el-form-item
            :label="lang_data == 1 ? '牙位:' : 'Tooth bit：'"
            prop="name"
          >
            <ToothPosition
              ref="toothPosition"
              :tooth_top_left.sync="form.tooth_top_left"
              :tooth_top_right.sync="form.tooth_top_right"
              :tooth_bottom_left.sync="form.tooth_bottom_left"
              :tooth_bottom_right.sync="form.tooth_bottom_right"
              @change="onFormChange"
              :isDisabled="true"
              :selectable_tooth_top_left="form.project.tooth_top_left"
              :selectable_tooth_top_right="form.project.tooth_top_right"
              :selectable_tooth_bottom_left="form.project.tooth_bottom_left"
              :selectable_tooth_bottom_right="form.project.tooth_bottom_right"
              :toothStates="toothStates"
            ></ToothPosition>
          </el-form-item>
        </template>
        <el-form-item
          v-else
          :label="lang_data == 1 ? '上下颌:' : 'On the lower jaw：'"
          prop="name"
        >
          <el-checkbox-group
            v-model="form.up_down_tooth"
            @change="onFormChange"
          >
            <el-checkbox
              :label="'1'"
              :disabled="old_up_down_tooth.indexOf('1') == -1"
              >{{ lang_data == 1 ? "上颌" : "Upper jaw" }}</el-checkbox
            >
            <el-checkbox
              :label="'2'"
              :disabled="old_up_down_tooth.indexOf('2') == -1"
              >{{ lang_data == 1 ? "下颌" : "The jaw" }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          :label="lang_data == 1 ? '数量:' : 'Quantity：'"
          prop="name"
        >
          <el-input-number
            size="small"
            v-if="form.project.input_type == 3"
            v-model="form.quantity"
            :controls="false"
          ></el-input-number>
          <span v-else>{{ form.quantity }}</span>
        </el-form-item>
        <el-form-item
          :label="lang_data == 1 ? '属性:' : 'Attribute：'"
          prop="name"
          v-if="attributesList.length > 0"
        >
          <div v-for="(items, i) in attributesList" :key="i">
            <template v-if="items.type.value == 1">
              <el-divider content-position="left" style="margin: 14px 0">
                <span v-if="items.required.value == 1" style="color: #f56c6c"
                  >*</span
                >
                {{ items.name }}
                <i
                  v-if="items.value"
                  class="el-icon-error ml10"
                  @click="items.value = ''"
                ></i
              ></el-divider>
              <el-radio-group v-model="items.value">
                <el-radio
                  v-for="(item1, i1) in items.content"
                  :key="i1"
                  :label="item1"
                  >{{ item1 }}</el-radio
                >
              </el-radio-group>
            </template>
            <template v-else-if="items.type.value == 2">
              <el-divider content-position="left">
                <span v-if="items.required.value == 1" style="color: #f56c6c"
                  >*</span
                >
                {{ items.name }}
              </el-divider>
              <el-checkbox-group v-model="items.value">
                <el-checkbox
                  v-for="(item2, i2) in items.content"
                  :key="i2"
                  :label="item2"
                ></el-checkbox>
              </el-checkbox-group>
            </template>
            <template v-else-if="items.type.value == 3">
              <el-divider content-position="left">
                <span v-if="items.required.value == 1" style="color: #f56c6c"
                  >*</span
                >
                {{ items.name }}
              </el-divider>
              <el-select
                v-model="items.value"
                :placeholder="lang_data == 1 ? '请选择' : 'Please choose'"
                class="mb10"
                clearable
              >
                <el-option
                  v-for="(item3, i) in items.content"
                  :key="i"
                  :label="item3"
                  :value="item3"
                >
                </el-option>
              </el-select>
            </template>
          </div>
        </el-form-item>
      </template>
    </el-form>
    <div slot="footer" style="text-align: center">
      <el-button size="small" @click="closeModal()">{{
        lang_data == 1 ? "取消" : "Cancel"
      }}</el-button>
      <el-button size="small" type="primary" @click="onConfirm()">{{
        lang_data == 1 ? "确认" : "Confirm"
      }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import ToothPosition from "./toothPosition";
export default {
  components: {
    ToothPosition,
  },
  props: {
    list: {
      type: Array,
    },
    row: {
      type: Object,
    },
    factory_id: [String, Number],
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      form: {
        project_id: undefined,
        project: null,
        tooth_top_left: [], // 牙位上左
        tooth_top_right: [], // 牙位上右
        tooth_bottom_left: [], // 牙位下左
        tooth_bottom_right: [], // 牙位下右
        up_down_tooth: [],
        quantity: 0,
      },
      projectlist: [],
      old_up_down_tooth: [],
      rules: [],
      attributesList: [],
      toothStates: "",
    };
  },
  // computed: {
  //   toothCount() {
  //     if (!this.form.project) return 0;
  //     if (this.form.project.input_type == 1) {
  //       let top_left_len = this.form.tooth_top_left.length;
  //       let top_right_len = this.form.tooth_top_right.length;
  //       let bottom_left_len = this.form.tooth_bottom_left.length;
  //       let bottom_right_len = this.form.tooth_bottom_right.length;
  //       return top_left_len + top_right_len + bottom_left_len + bottom_right_len;
  //     } else if (this.form.project.input_type == 2) {
  //       return this.form.up_down_tooth.length;
  //     }
  //   }
  // },
  async mounted() {
    await this.getToothStates();
    this.projectlist = this.list;
  },
  methods: {
    async getToothStates() {
      return this.$axios.post("/ucenter/getToothShowType").then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.toothStates = res.data;
        }
      });
    },
    async attributes(val) {
      return this.$axios
        .post("/orders/orders/getProjectAttributeList", {
          factory_id: this.factory_id,
          category_id: val,
        })
        .then((res) => {
          if (res.code == "1") {
            this.attributesList = res.data.list;
            this.attributesList.forEach((item) => {
              this.$set(item, "attribute_id", item.id);
              if (item.type.value == 1) {
                this.$set(item, "value", "");
              } else if (item.type.value == 2) {
                this.$set(item, "value", []);
              } else if (item.type.value == 2) {
                this.$set(item, "value", "");
              }
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    onFormChange() {
      this.form.quantity = this.getToothCount();
    },
    getToothCount() {
      if (!this.form.project_id) return 0;
      if (
        this.form.project.input_type == 1 ||
        this.form.project.input_type == 3
      ) {
        let top_left_len = this.form.tooth_top_left.length;
        let top_right_len = this.form.tooth_top_right.length;
        let bottom_left_len = this.form.tooth_bottom_left.length;
        let bottom_right_len = this.form.tooth_bottom_right.length;
        return (
          top_left_len + top_right_len + bottom_left_len + bottom_right_len
        );
      } else if (this.form.project.input_type == 2) {
        return this.form.up_down_tooth.length;
      }
      return 0;
    },
    async getProjectTreeData() {
      let res = await this.$axios.post("/setting/factory/getProjectTree", {
        factory_id: this.factoryId,
      });
      if (res.code == 1) {
        let list = res.data.list;
        this.projectTree = this.$util.arrayToTree(list, 0, "parent_id");
      }
    },
    onSelectProject() {
      let project_id = this.form.project_id,
        project = this.projectlist.filter((item) => {
          return item.project_id == project_id;
        })[0];
      if (project && project.is_expire_timeout == 1) {
        this.$message.warning(
          this.lang_data == 1
            ? "该项目已过保，不可选择！"
            : "This item is overinsured and not optional"
        );
        this.project = {};
        this.form.project_id = "";
        return;
      }
      console.log(this.form);
      // 项目为空时选择新项目 或者 项目有值切项目与旧项目不相同时 重置牙位和上下颌
      if (this.form.project && this.form.project.project_id !== project_id) {
        this.form.tooth_top_left = ""; // 牙位上左
        this.form.tooth_top_right = ""; // 牙位上右
        this.form.tooth_bottom_left = ""; // 牙位下左
        this.form.tooth_bottom_right = ""; // 牙位下右
        this.form.up_down_tooth = []; // 上下颌
      }

      this.form.project_id = project_id;
      this.form.project = project;
      this.form.tooth_top_left = project.tooth_top_left; // 牙位上左
      this.form.tooth_top_right = project.tooth_top_right; // 牙位上右
      this.form.tooth_bottom_left = project.tooth_bottom_left; // 牙位下左
      this.form.tooth_bottom_right = project.tooth_bottom_right; // 牙位下右
      this.form.up_down_tooth = project.tooth_type
        ? project.tooth_type.split(",")
        : []; // 上下颌
      this.old_up_down_tooth = project.tooth_type
        ? project.tooth_type.split(",")
        : [];
      // if(this.$refs.toothPosition){
      //   this.$refs.toothPosition.initOrgData()
      // }
      this.attributes(this.form.project.category_1_id);
      this.onFormChange();
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },
    closeModal(isrefresh) {
      this.$emit("close", isrefresh);
    },
    onConfirm() {
      this.$refs.form.validate((val) => {
        if (val) {
          if (!this.form.project) {
            this.$message.warning(
              this.lang_data == 1 ? "请选择项目!" : "Please select an item"
            );
            return;
          }
          if (
            this.form.project.input_type == 1 ||
            this.form.project.input_type == 3
          ) {
            if (this.form.quantity == 0) {
              this.$message.warning(
                this.lang_data == 1
                  ? "请选择牙位!"
                  : "Please select the tooth position"
              );
              return;
            }
          } else if (this.form.project.input_type == 2) {
            if (this.form.up_down_tooth.length == 0) {
              0;
              this.$message.warning(
                this.lang_data == 1
                  ? "请选择上下颌!"
                  : "Please select upper and lower jaw"
              );
              return;
            }
          }
          let required = true;
          this.attributesList.forEach((item) => {
            if (item.required.value == 1) {
              if (!item.value || item.value.length < 1) {
                required = false;
              }
            }
          });
          if (!required) {
            return this.$message.warning(
              this.lang_data == 1
                ? "请选择必填属性!"
                : "Please select required properties"
            );
          }
          let data = {
            project: this.form.project,

            tooth_top_left: this.form.tooth_top_left, // 牙位上左
            tooth_top_right: this.form.tooth_top_right, // 牙位上右
            tooth_bottom_left: this.form.tooth_bottom_left, // 牙位下左
            tooth_bottom_right: this.form.tooth_bottom_right, // 牙位下右

            up_down_tooth: this.form.up_down_tooth, // 上下颌
            quantity: this.form.quantity,
            attributesList: this.attributesList,
          };
          this.$emit("confirm", data);
        }
      });
    },
  },
};
</script>

