<template>
  <div
    class="afterSalesDetails"
    v-loading="loading"
    :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
  >
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/afterSales' }">{{
          lang_data == 1 ? "售后管理" : "After sale management"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          lang_data == 1 ? "售后单详情" : "Fter-sale service"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="con" v-if="!loading">
        <div class="mt20">
          <span class="subtitle">
            <span class="txt">{{
              lang_data == 1 ? "订单状态" : "Basic Information"
            }}</span>
          </span>
        </div>
        <el-row style="width: 670px" class="mt30">
          <ProcessStep :data="info.step"></ProcessStep>
        </el-row>
        <el-row type="flex">
          <ul class="sub-left mb20">
            <li>
              <!-- //返工状态：1：待寄件，2：待接单，3：已接单，4：生产中，5：待发货，6：已发货，7：返工完成，10：已取消 rework_status_show
      //退货状态：1待寄件，2等待厂家签收，3等待厂家处理退货，4退货完成，5已取消，6已拒绝 reback_status_show -->
              <template
                v-if="
                  info.rework_status_show.value == 1 ||
                  info.reback_status_show.value == 1
                "
              >
                <el-button
                  type="primary"
                  size="small"
                  round
                  class="mt20"
                  @click="expressNumberShow = true"
                >
                  {{ lang_data == 1 ? "上传快递单" : "upload Tracking No." }}
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  class="mt20"
                  round
                  @click="$router.push({ path: '/freedelivery' })"
                  v-if="usercenter.is_free_send == 1"
                >
                  {{ lang_data == 1 ? "寄件" : "Mail" }}
                </el-button>
              </template>
              <el-button
                size="small"
                round
                class="mt20"
                v-if="
                  [1, 2, 3, 4, 5].includes(info.rework_status_show.value) ||
                  [1, 2, 3].includes(info.reback_status_show.value)
                "
                @click="cancelOrder"
              >
                {{ lang_data == 1 ? "取消申请" : "Withdraw" }}
              </el-button>
              <el-button
                type="primary"
                size="small"
                class="mt20"
                round
                v-if="
                  [1, 2].includes(info.rework_status_show.value) ||
                  [1, 2].includes(info.reback_status_show.value)
                "
                @click="repairShow = true"
              >
                {{ lang_data == 1 ? "修改" : "Modify" }}
              </el-button>
              <el-button
                type="primary"
                size="small"
                class="mt20"
                round
                v-if="
                  !(
                    info.type == 1 ||
                    [10].includes(info.rework_status_show.value)
                  )
                "
                @mouseenter.native="enter"
                @mouseleave.native="leave"
              >
                {{ lang_data == 1 ? "生产进度" : "Production progress" }}
              </el-button>
            </li>
            <li>
              <span class="subtitle">
                <span class="txt">{{
                  lang_data == 1 ? "基本信息" : "Basic Information"
                }}</span>
              </span>
            </li>
            <li>
              <el-form
                label-width="100px"
                label-position="left"
                class="miniSzie"
              >
                <el-row>
                  <el-form-item
                    :label="lang_data == 1 ? '原订单号' : 'Original Order No.'"
                    v-if="info.source_order"
                    >{{ info.source_order.order_no }}</el-form-item
                  >
                  <el-form-item
                    :label="
                      oprParams.txt + (lang_data == 1 ? '编号' : 'Number')
                    "
                    >{{ info.reback_no }}</el-form-item
                  >
                  <el-form-item :label="lang_data == 1 ? '工厂' : 'Factory'">{{
                    info.order_info.factory_name
                  }}</el-form-item>
                  <el-form-item :label="lang_data == 1 ? '医生' : 'Doctor'">{{
                    info.order_info.doctor_name
                  }}</el-form-item>
                  <el-form-item :label="lang_data == 1 ? '患者' : 'Patient'">{{
                    info.order_info.patient_name
                  }}</el-form-item>
                  <el-form-item :label="lang_data == 1 ? '诊所' : 'Clinic'">{{
                    info.order_info.clinic_name
                  }}</el-form-item>
                  <el-form-item
                    :label="lang_data == 1 ? '订单号' : 'Order number'"
                    v-if="info.type == 2"
                    >{{ info.order_info.order_no }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '颈部牙齿颜色' : 'Shade-Gingival'"
                    >{{ info.order_info.up_color_name }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '体部牙齿颜色' : 'Shade-Body'"
                    >{{ info.order_info.color_name }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '切端牙齿颜色' : 'Shade-Incisal'"
                    >{{ info.order_info.low_color_name }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '基牙颜色' : 'Shade-Abutment'"
                    >{{ info.butment_color_name }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '下单码' : 'Order code'"
                    >{{ info.new_order_code }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '状态' : 'State'"
                    v-if="info.type == 2"
                  >
                    {{ info.rework_status_show.label }}
                  </el-form-item>
                  <el-form-item
                    :label="lang_data == 1 ? '状态' : 'State'"
                    v-if="info.type == 1"
                  >
                    {{ info.reback_status_show.label }}
                  </el-form-item>

                  <el-form-item
                    :label="lang_data == 1 ? '订单类型' : 'Order Type'"
                    >{{ info.type_show.label }}</el-form-item
                  >
                </el-row>
              </el-form>
            </li>
            <li>
              <span class="subtitle">
                <span class="txt"
                  >{{ oprParams.txt
                  }}{{ lang_data == 1 ? "项目" : "Project" }}</span
                >
              </span>
              <span class="fr"
                >{{ lang_data == 1 ? "合计" : "Total" }}：{{
                  info.order_info.order_amount
                }}</span
              >
            </li>
            <li>
              <el-table
                header-row-class-name="item-table"
                row-class-name="item-row"
                class="itemTable"
                :data="project_list"
                style="width: 730px"
                border
              >
                <el-table-column
                  prop="project_name"
                  width="180px"
                  :label="lang_data == 1 ? '项目名称' : 'Item'"
                >
                </el-table-column>
                <el-table-column
                  prop="category_1_name"
                  :label="lang_data == 1 ? '项目大类' : 'Category'"
                  width="88"
                >
                </el-table-column>
                <el-table-column
                  :label="
                    lang_data == 1
                      ? '牙位/上下颌'
                      : 'Tooth bit / On the lower jaw'
                  "
                  :width="toothStates == 3 ? '350px' : '180px'"
                >
                  <template slot-scope="scope">
                    <template v-if="scope.row.up_down_tooth.length > 0">
                      {{
                        scope.row.up_down_tooth
                          .map((val) => {
                            return {
                              1: lang_data == 1 ? "上颌" : "Upper jaw",
                              2: lang_data == 1 ? "下颌" : "The jaw",
                            }[val];
                          })
                          .join("、")
                      }}
                    </template>
                    <template v-else>
                      <ToothPosition
                        type="preview"
                        :tooth_top_left="scope.row.tooth_top_left"
                        :tooth_top_right="scope.row.tooth_top_right"
                        :tooth_bottom_left="scope.row.tooth_bottom_left"
                        :tooth_bottom_right="scope.row.tooth_bottom_right"
                        :toothStates="toothStates"
                      ></ToothPosition>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="count"
                  :label="lang_data == 1 ? '数量' : 'Quantity'"
                ></el-table-column>
                <el-table-column
                  prop="price"
                  :label="lang_data == 1 ? '单价' : 'Unit price'"
                >
                </el-table-column>
                <el-table-column
                  prop="row_total"
                  :label="lang_data == 1 ? '小计' : 'Subtotal'"
                >
                </el-table-column>
              </el-table>
            </li>
            <li>
              <el-form
                label-width="110px"
                label-position="left"
                class="miniSzie"
              >
                <el-form-item
                  :label="oprParams.txt + (lang_data == 1 ? '原因' : 'Why')"
                  >{{ info.rework_reason }}</el-form-item
                >
                <el-form-item
                  :label="
                    oprParams.txt + (lang_data == 1 ? '说明' : 'Instructions')
                  "
                >
                  {{ info.rework_reason_remark }}
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '申请时间' : 'Apply Date'"
                >
                  {{ $moment(info.add_time * 1000).format("YYYY-MM-DD hh:mm") }}
                </el-form-item>
              </el-form>
            </li>
            <li>
              <div class="subtitle">
                <span class="txt"
                  >{{ lang_data == 1 ? "收货地址" : "Address"
                  }}<span class="fs14 c999">{{
                    lang_data == 1
                      ? "（说明：默认为诊所收货地址）"
                      : " (Note: Default shipping address is the dental office address)"
                  }}</span></span
                >
              </div>

              <div class="addressInfo">
                {{ info.order_info.receiver_name }}
                <span class="ml20">{{ info.order_info.receiver_mobile }}</span
                ><br />
                {{ info.order_info.receiver_address }}
              </div>
            </li>
            <li>
              <span class="subtitle">
                <span class="txt">{{
                  lang_data == 1 ? "附件" : "Accessoris"
                }}</span>
              </span>
            </li>

            <AttachFile
              v-if="info.images.length > 0"
              :dataList="info.images"
            ></AttachFile>
            <!-- <div v-if="info.images.length > 0">
              <div
                v-for="(item, index) in info.images"
                :key="index"
                style="margin-bottom: 5px"
              >
                <el-link
                  type="primary"
                  @click="downloadFile(item.url, item.name)"
                >
                  <span title="点击下载查看">{{ item.name }}</span>
                </el-link>
                
              </div>
            </div> -->
            <li v-else style="color: #999">
              {{ lang_data == 1 ? "暂无附件" : "No data" }}
            </li>
          </ul>

          <div class="sub-right">
            <ul>
              <li>
                <span class="subtitle">
                  <span class="txt">{{
                    lang_data == 1 ? "处理记录" : "Process Note"
                  }}</span>
                </span>
              </li>
              <li class="loglist">
                <el-timeline>
                  <el-timeline-item
                    v-for="(item, index) in activities"
                    :key="index"
                    :timestamp="item.timestamp"
                  >
                    <template v-if="item.type == 2">
                      <span class="c333">{{ item.title }}</span>
                      <div class="c666 mt5">
                        <ul>
                          <li
                            v-for="(params, index1) in item.content"
                            :key="index1"
                            class="flexdr flexd-x-c flexSB"
                          >
                            <span
                              >{{ params.field_label }}:
                              {{ params.field_value }}</span
                            >
                            <el-link
                              v-if="
                                params.is_show_express &&
                                params.is_show_express == 1
                              "
                              @click="expressInfoClick(params.field_value)"
                              >{{
                                lang_data == 1
                                  ? "查看物流"
                                  : "Check the logistics"
                              }}</el-link
                            >
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template v-else>
                      <span class="c333">{{ item.title }}</span>

                      <div class="c666 mt5">
                        <ul>
                          <li
                            v-for="(params, index1) in item.content"
                            :key="index1"
                            class="flexdr flexd-x-c flexSB"
                          >
                            <span
                              >{{ params.field_label }}:
                              <template
                                v-if="
                                  params.field_value > 100000 &&
                                  params.field_label.indexOf('时间') > -1
                                "
                              >
                                {{
                                  $moment(params.field_value * 1000).format(
                                    "YYYY-MM-DD hh:mm"
                                  )
                                }}
                              </template>
                              <template v-else>
                                {{ params.field_value }}
                              </template>
                            </span>
                          </li>
                          <template v-if="item.imgs.length > 0">
                            <li>
                              {{ lang_data == 1 ? "附件" : "Accessoris" }}:
                            </li>
                            <li class="imagesList mt5">
                              <div
                                v-for="(img, imgindex) in item.imgs"
                                :key="imgindex"
                              >
                                <img :src="img.url" alt="" />
                              </div>
                            </li>
                          </template>
                          <li v-else style="color: #999">
                            {{ lang_data == 1 ? "暂无附件" : "No data" }}
                          </li>
                        </ul>
                      </div>
                      <el-table
                        header-row-class-name="item-table"
                        v-if="item.list.length > 0"
                        row-class-name="item-row"
                        class="itemTable mt10"
                        :data="item.list"
                        style="width: 100%"
                        border
                      >
                        <el-table-column
                          prop="project_name"
                          :label="lang_data == 1 ? '项目名称' : 'Item'"
                          width="150"
                        >
                        </el-table-column>
                        <el-table-column
                          :label="
                            lang_data == 1
                              ? '牙位/上下颌'
                              : 'Tooth bit / On the lower jaw'
                          "
                          :width="toothStates == 3 ? '350px' : '180px'"
                        >
                          <template slot-scope="scope">
                            <template v-if="scope.row.up_down_tooth.length > 0">
                              {{
                                scope.row.up_down_tooth
                                  .map((val) => {
                                    return {
                                      1: lang_data == 1 ? "上颌" : "Upper jaw",
                                      2: lang_data == 1 ? "下颌" : "The jaw",
                                    }[val];
                                  })
                                  .join("、")
                              }}
                            </template>
                            <template v-else>
                              <ToothPosition
                                type="preview"
                                :tooth_top_left="scope.row.tooth_top_left"
                                :tooth_top_right="scope.row.tooth_top_right"
                                :tooth_bottom_left="scope.row.tooth_bottom_left"
                                :tooth_bottom_right="
                                  scope.row.tooth_bottom_right
                                "
                                :toothStates="toothStates"
                              ></ToothPosition>
                            </template>
                          </template>
                        </el-table-column>

                        <el-table-column
                          prop="quantity"
                          :label="lang_data == 1 ? '数量' : 'Quantity'"
                          width="85"
                        ></el-table-column>
                      </el-table>
                    </template>
                  </el-timeline-item>
                </el-timeline>
              </li>
            </ul>
          </div>
        </el-row>
      </div>
      <Processes
        v-if="processesShow"
        :orderinfo="processesInfo"
        @mouseover="enter"
        @mouseleave="leave"
        :top="310"
      ></Processes>

      <Repair
        v-if="repairShow"
        :repairShow="repairShow"
        :datainfo="
          Object.assign(this.info.order_info, {
            step: this.info.step,
          })
        "
        :type="this.orderType"
        :id="this.info.id"
        :source_order="this.info.source_order"
        @cancel-event="repairShow = false"
        @ok-event="okEvent"
      >
      </Repair>
      <ExpressNumber
        v-if="expressNumberShow"
        :expressNumberShow="expressNumberShow"
        :orderId="[this.info.source_order.id]"
        :express_no="this.info.express_no"
        @ok-event="okEvent"
        @cancel-event="expressNumberShow = false"
      >
      </ExpressNumber>
      <ExpressInfo
        v-if="expressInfoShow"
        :expressInfoShow="expressInfoShow"
        :tracking_no="field_value"
        @cancelEvent="expressInfoShow = false"
      ></ExpressInfo>
    </div>
    <!-- 消息 -->
    <div class="message" @click="msgboxShow = true">
      <el-badge :is-dot="unreadNum ? true : false" class="item">
        <i class="el-icon-chat-dot-round"></i>
      </el-badge>
    </div>
    <Msgbox
      v-if="msgboxShow"
      :msgboxShow="msgboxShow"
      :orderids="[$route.query.rebackid]"
      @cancel="msgboxShow = false"
    />
  </div>
</template>
<script>
import ProcessStep from "@/components/RepairProcessStep.vue";
import ToothPosition from "@/components/toothPosition.vue";
import Processes from "@/components/processes.vue";
import Repair from "@/components/repair.vue";
import ExpressNumber from "@/components/expressNumber.vue";
import ExpressInfo from "@/components/expressInfo_modal.vue";
import Msgbox from "@/components/msgbox.vue";
import AttachFile from "@/components/attachFile.vue";
export default {
  name: "aftersaleDetail",
  components: {
    ProcessStep,
    ToothPosition,
    Processes,
    Repair,
    ExpressNumber,
    ExpressInfo,
    Msgbox,
    AttachFile,
  },
  async mounted() {
    await this.getToothStates();
    if (this.$route.query.rebackid) {
      this.rebackid = parseInt(this.$route.query.id);
      this.orderid = parseInt(this.$route.query.rebackid);
    }
    this.getDetail();
  },
  watch: {
    messageList(data) {
      this.unreadNum = false;
      data.forEach((item) => {
        if (item.order_id == this.orderid) {
          this.unreadNum = true;
        }
      });
    },
  },
  computed: {
    usercenter() {
      return this.$store.state.usercenter.is_free_send
        ? this.$store.state.usercenter
        : JSON.parse(localStorage.userData);
    },
    oprParams: function () {
      let obj = {};
      if (this.orderType == 2) {
        obj = {
          posturl: "/orders/order_rework/save",
          reasontype: "is_suitable_outer",
          txt: this.lang_data == 1 ? "返工" : "Rework",
        };
      } else {
        obj = {
          posturl: "/orders/order_reback/save",
          reasontype: "is_suitable_reback",
          txt: this.lang_data == 1 ? "退货" : "Return goods",
        };
      }
      return obj;
    },
    messageList() {
      return this.$store.state.wsUnreadCount.arr;
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      info: {},
      project_list: [],
      loading: true,
      activities: [],
      form: {},
      orderType: "",
      processesShow: false,
      timer: null,
      processesInfo: {},
      rebackid: "",
      orderid: "",
      toothStates: "",
      repairShow: false,
      expressNumberShow: false,
      expressInfoShow: false,
      field_value: "",
      msgboxShow: false,
      unreadNum: false,
    };
  },

  methods: {
    async downloadFile(path, name) {
      const blob = await this.$axios.get(path, {
        responseType: "blob",
      });
      let blobFile = URL.createObjectURL(blob);

      let link = document.createElement("a");
      link.href = blobFile;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobFile);
    },
    okEvent() {
      this.expressNumberShow = false;
      this.repairShow = false;
      this.getDetail();
    },
    async getToothStates() {
      return this.$axios.post("/ucenter/getToothShowType").then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.toothStates = res.data;
        }
      });
    },
    getDetail() {
      let params = {
        id: this.rebackid,
      };

      this.$axios
        .get("/orders/order_reback/getDetail", { params })
        .then((res) => {
          this.loading = true;
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.loading = false;
            this.info = res.data;
            this.processesInfo = JSON.parse(JSON.stringify(this.info));
            this.processesInfo.id = this.processesInfo.new_order_id;
            this.processesInfo.produce_status_show =
              this.processesInfo.order_info.produce_status_show;
            this.project_list = this.info.project_list.map((item) => {
              return {
                project_id: item.project_id,
                project_name: item.project_name,
                input_type: item.input_type,
                // 牙位
                tooth_top_left: item.tooth_top_left + "",
                tooth_top_right: item.tooth_top_right + "",
                tooth_bottom_left: item.tooth_bottom_left + "",
                tooth_bottom_right: item.tooth_bottom_right + "",
                // 上下颌
                up_down_tooth: item.tooth_type
                  ? item.tooth_type.split(",")
                  : [],

                price: item.price,
                count: item.quantity,
                row_total: item.row_total,

                category_1_id: item.category_1_id,
                category_1_name: item.category_1_name,
              };
            });
            this.orderType = this.info.type_show.value;
            //处理记录
            this.reGroup(this.info.log);
            if (this.orderType == 2) {
              // ModalTool.create("MessageIMBell", {
              //   orderinfo: {
              //     order_id: this.info.new_order_id,
              //     order_no: this.info.new_order_no,
              //     order_code: this.info.new_order_code,
              //   },
              // });
            }
          }
        });
    },
    reGroup(data) {
      this.activities = data.map((item) => {
        let list = [],
          imgs = [];
        if (item.params.length > 0) {
          item.params.map((el, index) => {
            if (el.field_label == "返工项目" || el.field_label == "退货项目") {
              item.params.splice(index, 1);
              if (typeof el.field_value == "object") {
                list = el.field_value;
                list.map((item) => {
                  item.up_down_tooth = item.tooth_type
                    ? item.tooth_type.split(",")
                    : [];
                });
              }
            }

            if (el.field_label == "返工图片") {
              item.params.splice(index);
              imgs = el.field_value;
            }
          });
        }
        return {
          timestamp: this.$moment(new Date(item.add_time * 1000)).format(
            "YYYY-MM-DD HH:mm"
          ),
          title: item.title,
          content: item.params,
          type: item.type,
          list: list,
          imgs: imgs,
        };
      });
    },
    expressInfoClick(val) {
      this.field_value = val;
      this.expressInfoShow = true;
    },
    cancelOrder() {
      this.$confirm(
        this.lang_data == 1
          ? "确认要取消申请?"
          : "Confirm to cancel the application?",
        this.lang_data == 1 ? "提示" : "Note",
        {
          confirmButtonText: this.lang_data == 1 ? "确认" : "Confirm",
          cancelButtonText: this.lang_data == 1 ? "取消" : "Cancel",
        }
      )
        .then(() => {
          let params = {
            id: this.info.id,
          };
          this.$axios
            .post("/orders/order_reback/cancel", params)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.getDetail();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {});
    },
    enter() {
      if (this.timer) clearTimeout(this.timer);
      this.processesShow = true;
    },
    leave() {
      this.timer = setTimeout(() => {
        this.processesShow = false;
      }, 400);
    },
  },
};
</script>
<style lang="scss" scoped>
.afterSalesDetails {
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
  .message {
    font-size: 25px;
    position: fixed;
    bottom: 30%;
    right: 100px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #409eff;
    color: #fff;
  }
  .content {
    width: 1300px;
    margin: 0px auto;
    padding: 20px 0;
    .detail-bottom {
      display: flex;
      margin-bottom: 30px;
    }
    .sub-left {
      margin-right: 66px;
      flex: 1;
      li {
        padding-bottom: 20px;
      }
      .filelist {
        margin-left: 36px;
        display: flex;
        flex-direction: column;
      }
    }
    .sub-right {
      padding: 32px 24px;
      padding-top: 30px;
      width: 455px;
      min-height: 500px;
      background: #f5f6f6;
      > ul > li {
        margin-top: 25px;
      }
      .design {
        dd {
          margin-bottom: 15px;
          display: flex;
        }
        .de-t {
          width: 80px;
          display: inline-block;
          color: #666666;
        }
      }
      .SFinfo {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 10px;
        span:first-child {
          margin-right: 22px;
        }
      }
    }
    .routelist {
      margin-left: 50px;
      height: 250px;
      overflow-y: auto;
    }
  }
}
.bg-white {
  background-color: #fff;
  padding: 16px;
}
.expressInfo {
  background-color: #fff;
  padding: 13px 0 14px 13px;
  height: 250px;
  overflow-y: auto;
}

.requirement {
  line-height: 24px;
  margin-top: 8px;
}
.addressInfo {
  margin-top: 15px;
  line-height: 23px;
  font-weight: 550;
}
.imagesList {
  padding-left: 20px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  div {
    margin-right: 10px;
  }
  img {
    max-width: 100px;
    max-height: 100px;
  }
}
.remark {
  word-break: break-all;
  line-height: 26px;
  margin-top: 7px;
}
</style>
<style lang="scss">
.afterSalesDetails {
  .con {
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__content,
    .el-form-item__label {
      line-height: 30px;
    }
    .el-form-item__label {
      color: #999;
    }
    .el-form-item__content {
      font-weight: 550;
    }
  }
}
</style>
