<template>
  <!-- 编辑返工 申请返工 -->
  <el-dialog
    :title="
      (id
        ? lang_data == 1
          ? '编辑'
          : 'Edit'
        : lang_data == 1
        ? '申请'
        : 'To apply for') + optionTxt
    "
    :visible.sync="repairShow"
    width="800px"
    :before-close="cancel"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" element-loading-text="拼命加载中">
      <el-form
        ref="form"
        :model="form"
        label-width="90px"
        label-position="left"
        :rules="rules"
      >
        <el-row style="margin-right: -20px; margin-left: -20px">
          <ProcessStep :data="datainfo.step"></ProcessStep>
        </el-row>
        <el-row class="order_row">
          <span>{{ lang_data == 1 ? "订单号" : "Order No." }}</span
          >{{ source_order.order_no }}
        </el-row>
        <el-row>
          <div class="mt10 add_project">
            <div class="group-title required">
              {{ optionTxt }}{{ lang_data == 1 ? "项目" : "Item" }}
            </div>
            <el-button size="small" type="primary" @click="addProject">{{
              lang_data == 1 ? "添加" : "Add"
            }}</el-button>
          </div>
          <div>
            <el-table
              :data="form.project_list || []"
              style="margin-top: 10px"
              row-class-name="item-row"
              class="itemTable"
              stripe
              header-row-class-name="item-table"
              border
            >
              <el-table-column
                prop="project_name"
                :label="lang_data == 1 ? '项目名称' : 'Project name'"
              ></el-table-column>
              <el-table-column
                prop="category_1_name"
                width="80px"
                :label="lang_data == 1 ? '项目大类' : 'Project types'"
              ></el-table-column>
              <el-table-column
                :label="
                  lang_data == 1
                    ? '牙位/上下颌:'
                    : 'Tooth bit / On the lower jaw：'
                "
                :width="toothStates == 3 ? '350px' : '200px'"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.up_down_tooth.length == 0">
                    <ToothPosition
                      type="preview"
                      :tooth_top_left="scope.row.tooth_top_left"
                      :tooth_top_right="scope.row.tooth_top_right"
                      :tooth_bottom_left="scope.row.tooth_bottom_left"
                      :tooth_bottom_right="scope.row.tooth_bottom_right"
                      :toothStates="toothStates"
                    ></ToothPosition>
                  </template>
                  <template v-else>
                    {{
                      scope.row.up_down_tooth
                        .map((val) => {
                          return {
                            1: lang_data == 1 ? "上颌" : "Upper jaw",
                            2: lang_data == 1 ? "下颌" : "The jaw",
                          }[val];
                        })
                        .join("、")
                    }}
                  </template>
                </template>
              </el-table-column>

              <el-table-column
                prop="quantity"
                :label="lang_data == 1 ? '数量' : 'Quantity'"
                width="70px"
              ></el-table-column>

              <el-table-column
                :label="lang_data == 1 ? '操作' : 'Action'"
                width="70px"
              >
                <template slot-scope="scope">
                  <el-link type="danger" @click="removeProject(scope.$index)">{{
                    lang_data == 1 ? "删除" : "Delete"
                  }}</el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-row>
        <el-form-item
          class="mt10"
          :label="optionTxt + (lang_data == 1 ? '原因' : 'Why')"
          prop="reason"
          style="margin-bottom: 20px"
        >
          <el-select size="small" v-model="form.reason" style="width: 220px">
            <el-option
              :label="item.reason"
              :value="item.id"
              v-for="item in reasonlist"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="optionTxt + (lang_data == 1 ? '说明12333' : 'Instructions')"
          style="margin-bottom: 20px"
        >
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item :label="lang_data == 1 ? '附件' : 'Near'">
          <input
            type="file"
            hidden
            ref="attach_upload"
            @change="onSelectAttach"
            multiple
          />
          <div
            class="attachmentlist"
            type="primary"
            v-for="(item, index) in form.attachment"
            :key="index"
          >
            <div type="primary" class="img-item">
              <span title="在详情查看下载">{{ item.name }}</span>
            </div>
            <i
              class="el-icon-error delicon"
              :title="lang_data == 1 ? '删除附件' : 'Delete the near'"
              @click="removeAttachFile(index)"
            ></i>
          </div>
          <el-button
            v-if="form.attachment.length < 9"
            size="mini"
            type="primary"
            @click="$refs.attach_upload.click()"
            >上传附件</el-button
          >
        </el-form-item>
        <el-row>
          <div class="group-title mt10">
            {{ lang_data == 1 ? "收货地址" : "Shipping address"
            }}<span class="fs14 c999">{{
              lang_data == 1
                ? "（说明：默认为诊所收货地址）"
                : "（Note: Default shipping address is the dental office address"
            }}</span>
          </div>

          <div class="addressInfo mt10 mb10">
            {{ datainfo.receiver_name }}
            <span class="ml20">{{ datainfo.receiver_mobile }}</span
            ><br />
            {{ datainfo.receiver_address }}
          </div>
        </el-row>
        <el-row>
          <div class="group-title mb10">
            {{ lang_data == 1 ? "物流" : "Logistics" }}
          </div>
          <div style="width: 400px">
            <el-form-item
              :label="lang_data == 1 ? '快递公司：' : 'Courier company：'"
              size="small"
              style="margin-bottom: 20px"
            >
              <ExpressCompanySelect
                v-model="form.stl_express_company"
                style="width: 220px"
                :is-all="false"
              ></ExpressCompanySelect>
            </el-form-item>
            <el-form-item
              :label="lang_data == 1 ? '快递单号：' : 'Courier number：'"
            >
              <el-input
                v-model="form.stl_express_no"
                size="small"
                :placeholder="
                  lang_data == 1 ? '请输入快递单号' : 'Please enter'
                "
                style="width: 220px"
                clearable
              ></el-input>
            </el-form-item>
          </div>
        </el-row>
      </el-form>
    </div>
    <AddProjectModal
      v-if="projectModal.show"
      :row="projectEdit.row"
      :factory_id="datainfo.factory_id"
      :list="project_list"
      @close="projectModal.show = false"
      @confirm="projectModal.onConfirm"
    ></AddProjectModal>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel" size="small">取消</el-button>
      <el-button type="primary" @click="onConfirm" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { ExpressCompanySelect } from "@/components/select/index";
import ProcessStep from "@/components/RepairProcessStep";
import AddProjectModal from "@/components/addRepairProjectModal";
import ToothPosition from "@/components/toothPosition";
export default {
  components: {
    ProcessStep,
    AddProjectModal,
    ToothPosition,
    ExpressCompanySelect,
  },
  name: "repair",
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      rules: {
        reason: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请选择原因"
                : "Clear reasons for selection",
          },
        ],
      },
      form: {
        stl_express_no: "",
        reason: "",
        project_list: [],
        remark: "",
        attachment: [],
        stl_express_company: "",
      },
      project_list: [],
      reasonlist: [],
      projectModal: {
        show: false,
        onConfirm: () => {},
      },
      projectEdit: {},
      loading: false,
      toothStates: "",
    };
  },
  props: {
    repairShow: [Boolean],
    type: {
      type: [Number, String],
      default: 1,
    },
    datainfo: {
      type: Object,
    },
    id: {
      type: [Number, String],
    },
    source_order: {
      type: Object,
    },
  },
  computed: {
    optionTxt: function () {
      return this.type == 2
        ? this.lang_data == 1
          ? "返工"
          : "Rework"
        : this.lang_data == 1
        ? "退货"
        : "Reback";
    },
    oprParams: function () {
      let obj = {};
      if (this.type == 2) {
        obj = {
          posturl: "/orders/order_rework/save",
          reasontype: "is_suitable_outer",
        };
      } else {
        obj = {
          posturl: "/orders/order_reback/save",
          reasontype: "is_suitable_reback",
        };
      }
      return obj;
    },
  },
  async mounted() {
    await this.getToothStates();
    this.init();
  },
  methods: {
    async downloadFile(path, name) {
      const blob = await this.$axios.get(path, {
        responseType: "blob",
      });
      let blobFile = URL.createObjectURL(blob);

      let link = document.createElement("a");
      link.href = blobFile;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobFile);
    },
    async getToothStates() {
      return this.$axios.post("/ucenter/getToothShowType").then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.toothStates = res.data;
        }
      });
    },
    init() {
      this.getReasonlist();
      this.project_list = this.datainfo.project_list;
      if (this.id) {
        //修改返工申请，项目列表数据取源单项目
        this.loading = true;
        this.getDetail();
        this.getProjectList(this.source_order.id);
      }
    },
    getDetail() {
      this.$axios
        .post("/orders/order_reback/getDetail", {
          id: this.id,
        })
        .then((res) => {
          this.loading = false;
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.form.reason = res.data.rework_reason_id;
            this.form.remark = res.data.rework_reason_remark;
            this.form.attachment = res.data.images;
            this.form.stl_express_no = res.data.express_no;
            this.form.stl_express_company = res.data.express_company;

            // this.form.project_list = res.data.project_list

            this.form.project_list = res.data.project_list.map((item) => {
              return {
                project_id: item.project_id,
                project_name: item.project_name,
                input_type: item.input_type,
                // 牙位
                tooth_top_left: item.tooth_top_left + "",
                tooth_top_right: item.tooth_top_right + "",
                tooth_bottom_left: item.tooth_bottom_left + "",
                tooth_bottom_right: item.tooth_bottom_right + "",
                // 上下颌
                up_down_tooth: item.tooth_type
                  ? item.tooth_type.split(",")
                  : [],

                price: item.price,
                quantity: item.quantity,
                row_total: item.row_total,

                category_1_id: item.category_1_id,
                category_1_name: item.category_1_name,
              };
            });
          }
          setTimeout(() => {
            this.loading = false;
          });
        });
    },
    getProjectList(id) {
      this.$axios
        .post("/orders/orders/getOrderProject", {
          order_id: id,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.project_list = res.data.list.map((item) => {
              return {
                project_id: item.project_id,
                project_name: item.project_name,
                input_type: item.input_type,
                // 牙位
                tooth_top_left: item.tooth_top_left + "",
                tooth_top_right: item.tooth_top_right + "",
                tooth_bottom_left: item.tooth_bottom_left + "",
                tooth_bottom_right: item.tooth_bottom_right + "",
                // 上下颌
                up_down_tooth: item.tooth_type
                  ? item.tooth_type.split(",")
                  : [],

                price: item.price,
                quantity: item.quantity,
                row_total: item.row_total,

                category_1_id: item.category_1_id,
                category_1_name: item.category_1_name,
              };
            });
          }
        });
    },
    getReasonlist() {
      this.$axios
        .post("/setting/factory/getReworkReasonList", {
          factory_id: this.datainfo.factory_id,
          type: this.oprParams.reasontype,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.reasonlist = res.data.list;
          }
        });
    },
    addProject() {
      this.projectModal = {
        show: true,
        onConfirm: (data) => {
          // let count = 0;
          // if (!data.project) return (count = 0);
          // if (data.project.input_type == 1) {
          //   let top_left_len = data.tooth_top_left.length;
          //   let top_right_len = data.tooth_top_right.length;
          //   let bottom_left_len = data.tooth_bottom_left.length;
          //   let bottom_right_len = data.tooth_bottom_right.length;
          //   count =
          //     top_left_len + top_right_len + bottom_left_len + bottom_right_len;
          // } else if (data.project.input_type == 2) {
          //   count = data.up_down_tooth.length;
          // }
          if (
            this.form.project_list.filter(
              (item) => item.project_id == data.project.project_id
            ).length > 0
          ) {
            //判断项目是否重复
            this.$message.warning(
              this.lang_data == 1
                ? `[ ${data.project.project_name} ]已存在！`
                : `[ ${data.project.project_name} ] existing！`
            );
            return;
          }

          this.form.project_list.push({
            project_id: data.project.project_id,
            project_name: data.project.project_name,
            input_type: data.project.input_type,
            // 牙位
            tooth_top_left: data.tooth_top_left,
            tooth_top_right: data.tooth_top_right,
            tooth_bottom_left: data.tooth_bottom_left,
            tooth_bottom_right: data.tooth_bottom_right,
            // 上下颌
            up_down_tooth: data.up_down_tooth,

            price: "",
            quantity: data.quantity,

            category_1_id: data.project.category_1_id,
            category_1_name: data.project.category_1_name,
            attributesList: data.attributesList,
          });

          this.projectModal.show = false;
        },
      };
    },
    // 选择附件
    onSelectAttach(e) {
      let files = e.target.files;
      if (files.length == 0) return;

      for (let i = 0; i < files.length; i++) {
        // 如果是图片再生成一个blob_url字段，用作预览
        this.form.attachment.push({
          name: files[i].name,
          url: "",
          file: files[i],
          blob_url: URL.createObjectURL(files[i]),
        });
      }

      e.target.value = "";
    },
    removeProject(index) {
      this.form.project_list.splice(index, 1);
    },
    // 移除附件
    removeAttachFile(index) {
      this.form.attachment.splice(index, 1);
    },
    async uploadAttachFiles(files) {
      let list = [];
      files.forEach(async (item) => {
        // 未上传
        if (item.file) {
          list.push(
            // eslint-disable-next-line no-async-promise-executor
            new Promise(async (resolve) => {
              let url = await this.upload(item.file);
              item.url = url;
              item.file = null;
              if (item.blob_url) {
                URL.revokeObjectURL(item.blob_url); // 卸载临时图片
                item.blob_url = null;
              }
              resolve();
            })
          );
        }
      });
      return Promise.all(list);
    },
    upload(file) {
      return new Promise((resolve) => {
        this.$getuploadsign(4, file).then((res) => {
          if (res.res.code != 1) {
            this.$message(res.res.msg);
            return;
          }
          resolve(res.url);
        });
      });
    },
    onConfirm() {
      this.$refs.form.validate(async (val) => {
        if (val) {
          if (this.form.project_list.length == 0) {
            this.$message.warning(
              this.lang_data == 1 ? "请添加项目" : "Please add items"
            );
            return;
          }
          let data = {
            source_order_id: this.datainfo.id,
            rework_reason_id: this.form.reason,
            rework_reason_remark: this.form.remark,
            images: [],
            express_company: this.form.stl_express_no
              ? this.form.stl_express_company
              : "",
            express_no: this.form.stl_express_no,
            project_list: this.form.project_list.map((item) => {
              return {
                project_id: item.project_id,
                // 牙位
                tooth_top_left: item.tooth_top_left,
                tooth_top_right: item.tooth_top_right,
                tooth_bottom_left: item.tooth_bottom_left,
                tooth_bottom_right: item.tooth_bottom_right,
                // 上下颌
                tooth_type: item.up_down_tooth,

                quantity: item.quantity,
                attribute: JSON.stringify(item.attributesList),
              };
            }),
          };
          if (this.id) {
            data.id = this.id;
            data.new_order_id = this.datainfo.id;
            data.source_order_id = this.source_order ? this.source_order.id : 0;
          }
          //-------------上传附件-------------
          data.images = this.form.attachment; // 附件 {id: '', name: '', url: '', file: null}

          await this.uploadAttachFiles(data.images);

          let res = await this.$axios.post(this.oprParams.posturl, data);

          if (res.code == 1) {
            this.$message.success(res.msg);
            this.$emit("success", res.data.id);

            if (this.id) {
              this.okEvent();
            } else {
              this.$router.push("/orderindex/afterSalelist");
            }
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    cancel() {
      this.$emit("cancel-event");
    },
    okEvent() {
      this.$emit("ok-event");
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.group-title {
  color: #333333;
  font-size: 16px;

  font-weight: bold;
  &.required:before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
.order_row {
  margin-top: 20px;
  width: 100%;
  padding: 10px 0;
  background: #f5f6f6;
  color: #333;
  font-weight: 600;
  span {
    margin: 0 16px 22px;
  }
}
.add_project {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addressInfo {
  line-height: 23px;
}
.attachmentlist {
  display: flex;
  align-items: center;
  .img-item {
    display: inline-block;

    img {
      max-width: 110px;
      max-height: 110px;
    }
  }
  .delicon {
    font-size: 18px;
    color: #666;
    cursor: pointer;
    padding-left: 20px;
    display: none;
  }
  &:hover {
    .delicon {
      display: inline-block;
    }
  }
}
</style>
