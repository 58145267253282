<template>
  <div class="">
    <el-empty
      description="暂无数据"
      v-if="routelist.length == 0 && !loading"
    ></el-empty>
    <el-timeline
      class="routelist"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
    >
      <el-timeline-item
        v-for="(activity, index) in routelist"
        :key="index"
        :icon="activity.icon"
      >
        <span slot="dot">
          <div :class="activity.opcode == 80 ? 'code80' : 'codeDef'"></div>
        </span>
        <span class="state" :class="activity.opcode == 80 ? 'seColor b' : ''">{{
          activity._status
        }}</span>

        <div class="ml5">
          <span class="fs12">{{ activity.accept_time }}</span
          ><br />{{ activity.remark }}
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>
<script>
export default {
  name: "expressInfo",
  props: ["express_order_id", "tracking_no"],
  mounted() {
    this.getInfo();
  },
  data() {
    return {
      routelist: [],
      loading: true,
      lang_data: localStorage.lang_data || 1,
    };
  },
  methods: {
    getInfo() {
      let params = {
        express_order_id: this.express_order_id,
      };
      let postUrl = "/orders/express_order/getdetail";
      if (this.tracking_no) {
        params = { tracking_no: this.tracking_no };
        postUrl = "orders/express_free_send_order/getexpressinfo";
      }
      this.$axios.get(postUrl, { params }).then((res) => {
        this.loading = false;
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.routelist = res.data.route_list;
          this.routelist.map((item) => {
            return Object.assign(item, {
              _status:
                item.opcode == 80
                  ? this.lang_data == 1
                    ? "已签收"
                    : "Have to sign for"
                  : item.opcode == 0
                  ? this.lang_data == 1
                    ? "生成订单"
                    : "To generate orders"
                  : this.lang_data == 1
                  ? "运输中"
                  : "In the transport",
            });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-white {
  background-color: #fff;
  padding: 16px;
}

.routelist {
  padding-left: 70px;
  min-height: 250px;
  max-height: 460px;
  .codeDef {
    width: 9px;
    height: 9px;

    background: #cdcdcd;
    transform: rotate(45deg);
    margin-top: 10px;
  }
  .code80 {
    background: url("~@/assets/img/code80.png") no-repeat center center;
    width: 26px;
    height: 26px;
    margin-left: -8px;
    margin-top: 3px;
  }
  .state {
    position: absolute;
    left: -64px;
    top: 9px;
    width: 80px;
  }
  .el-timeline-item {
    &:last-child {
      padding-bottom: 0px;
    }
  }
}
</style>