<template>
  <div
    class="step-group"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
    :style="top > 0 ? { top: top  + 'px' } : ''"
    v-loading="loading"
    :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
  >
    <div class="tit">
      {{ lang_data == 1 ? "生产进度" : "Production progress" }}

      <el-tag v-if="status_txt" size="small">{{ status_txt }}</el-tag>
    </div>
    <div
      class="step-item"
      v-for="(item, index) in orderProjectList"
      :key="index"
    >
      <div class="step-tit">
        {{ item.category_1_name }} - {{ item.category_2_name }} -
        {{ item.project_name }}
        <span v-if="item.is_cancel.key == 1">{{ item.is_cancel.value }}</span>
      </div>

      <div class="step-content">
        <div v-if="item.is_have_produce_process_flow == -1">
          <!-- 本项目无需加工 -->
          <!-- <el-tag effect="dark" type="info" color="#a0adda" style="border: 0"
            >本项目无需加工</el-tag
          > -->
          <el-steps
            class="orderProcessSteps"
            finish-status="success"
            :space="95"
          >
            <!-- e.status.key -->
            <el-step
              status="success"
              :title="lang_data == 1 ? '完成' : 'Complete'"
            >
              <span slot="title">{{
                lang_data == 1 ? "完成" : "Complete"
              }}</span>
            </el-step>
          </el-steps>
        </div>
        <el-steps
          v-else
          class="orderProcessSteps"
          finish-status="success"
          :space="95"
          :active="item._active"
        >
          <!-- e.status.key -->
          <el-step
            v-for="e in item.schedule_list"
            :status="e._status"
            :title="e.produce_process_name"
            :key="e.produce_process_id"
          >
            <span
              slot="title"
              :style="{
                'margin-left':
                  (e.produce_process_name.length / 2 - 1) * -14 + 'px',
              }"
              >{{ e.produce_process_name }}</span
            >
          </el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "processes",
  mounted() {
    this.getProcessList();
  },
  props: ["orderinfo", "top"],
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      orderProjectList: [],
      status_txt: "",
      loading: true,
    };
  },
  methods: {
    getProcessList() {
      this.loading = true;
      let params = {
        order_id: this.orderinfo.id,
        factory_id: this.orderinfo.factory_id,
      };
      this.$axios
        .get("/orders/produce_transfer/getorderproduceschedule", { params })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.reSet(res.data);
            setTimeout(() => {
              this.loading = false;
            }, 100);
          }
        });
    },
    reSet(data) {
      data.order_project_list.forEach((item) => {
        let FindI = item.schedule_list.findIndex((j) => {
          // 记录当前 未加工 (1) 的索引
          return j.status.key == 1;
        });
        item._active = FindI === -1 ? item.schedule_list.length : FindI;
        if (item.schedule_list.length) {
          item.schedule_list.forEach((j) => {
            j._status = this.getStatus(j.machining_status.key);
          });
        }
      });
      this.orderProjectList = data.order_project_list;
      //生产状态：1待加工 2 加工中 3 待检 4已完成 10已取消
      if ([1, 4].includes(this.orderinfo.produce_status_show.value)) {
        this.status_txt = this.orderinfo.produce_status_show.label;
      } else if (this.orderinfo.produce_status_show.value == 2) {
        this.status_txt =
          this.lang_data == 1
            ? "当前工序："
            : "Process" + this.orderinfo.current_produce_process_name;
      }
    },
    getStatus(key) {
      /**
       * ["wait", "success"]
       * ["未加工", "完成"]
       * ["1", "2",]
       *  */
      let status = ["", "wait", "success", "none"];
      return status[key];
    },
    mouseover() {
      this.$emit("mouseover");
    },
    mouseleave() {
      this.$emit("mouseleave");
    },
  },
};
</script>
<style lang="scss" scoped>
.step-group {
  position: absolute;
  z-index: 5;
  top: 170px;
  background: #ffffff;
  border: 0px solid #eceef3;
  box-shadow: 3px 4px 18px 0px rgba(42, 42, 42, 0.18);
  width: 730px;
  min-height: 300px;
  padding: 30px 50px;
  box-sizing: border-box;
  .tit {
    font-weight: bold;
    color: #333333;
    font-size: 20px;
    margin-bottom: 25px;
  }

  .step-item {
    .step-tit {
      margin: 10px 0;
      font-size: 16px;
      color: #5f6395;
      border-bottom: 1px solid #eceef3;
      padding-bottom: 16px;
      span {
        font-size: 12px;
        color: #aaa;
      }
    }
  }
  .step-content {
    margin-bottom: 30px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    .el-steps {
      margin-left: 32px;
      margin-bottom: 10px;
    }
    .el-step {
      width: 100px;
      flex-shrink: 0;
      max-width: none !important;
    }
  }
}
</style>