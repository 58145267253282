<template>
  <div class="step-r">
    <el-steps :active="active" process-status="isCurrent" align-center>
      <template v-if="data">
        <el-step v-for="(item, index) in data" :key="index" :title="item.label">
          <div slot="icon">
            <span class="circleStep">{{ index + 1 }}</span>
          </div>
        </el-step>
      </template>
      <template v-else>
        <el-step
          :title="
            lang_data == 1
              ? '买家返货&提交申请'
              : 'Buyer returns & submits application'
          "
          description=""
        >
          <div slot="icon">
            <span class="circleStep">1</span><span class="stepLine"></span>
          </div>
        </el-step>
        <el-step
          :title="lang_data == 1 ? '厂家处理返货' : 'Handling Returns'"
          description=""
        >
          <div slot="icon"><span class="circleStep">2</span></div>
        </el-step>
        <el-step
          :title="lang_data == 1 ? '厂家发货' : 'Deliver goods'"
          description=""
        >
          <div slot="icon"><span class="circleStep">3</span></div>
        </el-step>
        <el-step
          :title="lang_data == 1 ? '返工完成' : 'Complete'"
          description=""
        >
          <div slot="icon"><span class="circleStep">4</span></div>
        </el-step>
      </template>
    </el-steps>
  </div>
</template>
<script>
export default {
  props: ["data"],
  name: "RepairProcessStep",
  mounted() {
    this.setAction();
  },
  watch: {
    data: function () {
      this.setAction();
    },
  },
  data() {
    return {
      active: 1,
      lang_data: localStorage.lang_data || 1,
    };
  },
  methods: {
    setAction() {
      if (this.data) {
        this.data.map((el, index) => {
          if (el.is_current == 1) {
            this.active = index + 1;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scope>
.step-r {
  .el-step__title {
    color: #c0c4cc;
  }
  .el-step__title.is-finish {
    color: #333333;
    font-weight: 550;
  }
  .el-step__title.is-process {
    color: #999;
    font-weight: normal;
  }
  .is-isCurrent {
    .el-step__icon.is-text {
      width: 34px;
      height: 34px;
      background-color: #d7d7d7;
      border-color: #d7d7d7;
    }
  }
  .el-step__head.is-finish {
    color: #424ccc;
    border-color: #424ccc;
  }
  .el-step__icon.is-text {
    width: 34px;
    height: 34px;
    background-color: #d7d7d7;
    border-color: #d7d7d7 !important;
  }
  .el-step.is-horizontal .el-step__line {
    top: 15px;
  }
  .circleStep {
    color: #999;
    background-color: #fff;
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    line-height: 26px;
  }
  .is-finish .circleStep {
    color: #fff;
    background-color: #424ccc;
  }
  // .is-finish .stepLine{
  //   width: 100%;
  //    background-color: #424ccc;
  //    height: 2px;
  //    display: inline-block;
  // }
}
</style>